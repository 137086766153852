.header_wrapper {
    width: 100%;
    padding: 20px 0;
    position: sticky;
    z-index: 10;
    background-color: var(--clr-white);
    top: 0;
    opacity: 0.95;
}
.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_right,
.header_left {
    flex-basis: 25%;
}
.header_right__menu {
    cursor: pointer;
    height: 30px;
    width: 30px;
}
.header_logo {
    max-width: 300px;
    flex-basis: 50%;
    text-align: center;
}
.header_logo__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}
.header_left {
    display: flex;
    gap: 30px;
    justify-content: flex-end;
}
.header_left__search {
    height: 30px;
    width: 30px;
    cursor: pointer;
}
@media only screen and (max-width: 610px) {
    .header_logo {
        max-width: 150px;
        max-height: 62px;
    }
    .header_left__search,
    .header_right__menu {
        height: 20px;
        width: 20px;
    }
}
@media only screen and (min-width: 610px) and (max-width: 1200px) {
    .header_logo {
        max-width: 200px;
        max-height: 62px;
    }
    .header_left__search,
    .header_right__menu {
        height: 25px;
        width: 25px;
    }
}
