.badge {
    position: relative;
    cursor: pointer;
}
.badge__count {
    position: absolute;
    background-color: var(--clr-black);
    color: var(--clr-white);
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 5px;
    font-size: 10px;
    right: -5px;
    top: -10px;
    font-weight: 500;
    text-align: center;
}
.badge__icon {
    height: 30px;
    width: 30px;
}
@media only screen and (max-width: 610px) {
    .badge__icon {
        height: 20px;
        width: 20px;
    }
    .badge__count {
        right: -8px;
        height: 18px;
        width: 18px;
        font-size: 8px;
    }
}
@media only screen and (min-width: 610px) and (max-width: 1200px) {
    .badge__icon {
        height: 25px;
        width: 25px;
    }
}