.modal_notification {
  position: fixed;
  top: 10px;
  left: -100%;
  opacity: 1;
  z-index: 100;
  padding: 5px;
  min-width: 250px;
  background-color: var(--clr-black);
  border-radius: 10px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  transition: all 0.2s;
  color: var(--clr-white);
  text-align: center;
}
.modal_notification.modal_notification__visible {
  left: 10px;
}
