.product_detail__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 50px 20px;
    background-color: var(--clr-white);
    border-radius: 20px;
}

.product_detail__slider_wrapper {
    max-width: 320px;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
}
/**Desktop Query*/
@media only screen and (min-width: 768px) {
    .product_detail__wrapper {
        margin-top: 50px;
    }
}

/*Tablet Query*/
@media only screen and (min-width: 581px) and (max-width: 768px) {
    .product_detail__wrapper {
        margin-top: 20px;
    }
}

/*Mobile Query*/
@media only screen and (max-width: 580px) {
    .product_detail__wrapper {
        flex-direction: column;
        margin-top: 10px;
        padding: 30px 10px;
    }
    .product_detail__slider_wrapper {
        max-width: 100%;
        width: 100%;
    }
}
