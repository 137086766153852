.modal_search {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: -100%;
    z-index: 10;
    opacity: 0.98;
    background-color: var(--clr-white);
    overflow: hidden;
    transition: right 0.5s ease-in;
}
.modal_search.visible {
    right: 0;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
    position: relative;
}
.modal_search_header {
    display: flex;
    flex-grow: 0;
    gap: 20px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}
.modal_search_header__form {
    border: 1px solid var(--clr-black);
    width: 100%;
    border-radius: 10px;
    padding: 2px 10px;
    display: flex;
}
.modal_search_header__form input[type="text"] {
    border: none;
    outline: none;
    height: 40px;
    flex: 1 0 auto;
    background: transparent;
    color: var(--clr-black);
}
.modal_search_header__form button {
    background-color: var(--clr-white);
    padding: 5px 20px;
    font-weight: 600;
    color: var(--clr-black);
    border: none;
    cursor: pointer;
}
.classes.modal_search_header__close {
    text-align: end;
    margin: 10px 0;
}
.classes.modal_search_header__close img {
    cursor: pointer;
}
.modal_search__loader {
    margin: auto;
}
.modal_search_nos_found {
    position: relative;
    width: fit-content;
    padding: 5px 0;
}
.modal_search_nos_found::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--clr-black);
}
.modal_search_results_wrapper {
    flex-grow: 1;
    overflow: scroll;
    margin-top: 10px;
}
.modal_search_results_wrapper::-webkit-scrollbar {
    width: 14px;
}
.modal_search_results_wrapper::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
    background-color: var(--clr-black);
}
.modal_search__results {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(400px, auto);
    column-gap: 10px;
    row-gap: 20px;
}
@media only screen and (max-width: 610px) {
    .modal_search__results {
        grid-template-columns: 1fr;
    }
}
@media only screen and (min-width: 610px) and (max-width: 1200px) {
    .modal_search__results {
        grid-template-columns: 1fr 1fr;
    }
}
