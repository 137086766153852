body {
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #82516f;
    background-image: linear-gradient(to left, #8b607b, #a0678a, #a0678a);
    position: relative;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --clr-white: #ffffff;
    --clr-black: #000000;
    --clr-rose: #a0678a;
    --clr-green: rgb(0, 128, 0);
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
.h-100 {
    height: 100%;
}
.w-100 {
    width: 100%;
}
.mt-60 {
    margin-top: 60px;
}
