.order_success__box {
    padding: 50px;
    box-shadow: -2px 13px 44px -8px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--clr-white);
}
.checkmark {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: var(--clr-green);
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px var(--clr-green);
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.9s ease-in-out 0.9s both;
    margin: auto;
    animation-iteration-count: infinite;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--clr-green);
    fill: var(--clr-white);
    animation: stroke 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.8s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    animation-iteration-count: infinite;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px var(--clr-green);
    }
}

.successMessage {
    margin-top: 10px;
    padding: 10px;
    font-size: 18px;
    color: var(--clr-green);
    text-align: center;
}
.orderNumber {
    color: var(--clr-green);
    text-align: center;
}

.orderNumber span {
    font-weight: bold;
    font-size: 20px;
    padding: 0 10px;
    color: var(--clr-black);
}
/**Desktop Query*/
@media only screen and (min-width: 768px) {
    .order_success__box {
        width: 50%;
    }
}

/*Tablet Query*/
@media only screen and (min-width: 581px) and (max-width: 768px) {
    .order_success__box {
        width: 70%;
    }
}

/*Mobile Query*/
@media only screen and (max-width: 580px) {
    .order_success__box {
        padding: 20px 10px;
        width: 90%;
    }
}
