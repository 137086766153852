.grid__products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.grid__product {
    flex-basis: 33.33333%;
    padding: 15px;
}
.grid__products_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media only screen and (max-width: 610px) {
    .grid__product {
        flex-basis: 100%;
    }
}
@media only screen and (min-width: 610px) and (max-width: 1200px) {
    .grid__product {
        flex-basis: 50%;
    }
}