@media only screen and (max-width: 610px) {
    .category_wrapper .wrapper {
        display: grid;
        grid-template-columns: 1fr !important;
    }
    .menu:not(.logo) {
        height: 25px !important;
        width: 25px !important;
    }
    .center_part {
        max-width: 200px !important;
        max-height: 62px !important;
    }
    .badge {
        right: -5px !important;
        width: 20px !important;
        height: 20px !important;
        font-size: 8px;
    }
}

@media only screen and (min-width: 610px) and (max-width: 1200px) {
    .category_wrapper .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
    }
    .menu:not(.logo) {
        height: 25px !important;
        width: 25px !important;
    }
    .center_part {
        max-width: 200px !important;
        max-height: 62px !important;
    }
    .badge {
        right: -5px !important;
        width: 20px !important;
        height: 20px !important;
        font-size: 8px;
    }
}

